import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Observable } from 'rxjs';
import { GoogleMapService } from 'src/app/services/google-map.service';
import { IMrhst } from 'src/lib/repository/mrhst/mrhst.model';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnInit {
  #center?: { lat: number; lng: number };

  /** 편집(마커 이동) 불가능 여부 */
  @Input() disabled = false;

  /** 호텔정보 표시 타입 */
  @Input() infoDisplayMode = '';

  /** 지도 가운데 기준이 되는 좌표 */
  @Input()
  set center(v: { lat: number; lng: number }) {
    this.#center = v;
    if (v) {
      if (this.markers.length === 0) {
        // this.markers.push(v);
      } else {
        const [marker] = this.markers;
        if (v.lat !== marker.lat || v.lng !== marker.lng) {
          // this.markers[0] = { ...v };
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get center(): { lat: number; lng: number } {
    return this.#center!;
  }

  /** 구글 지도 사용하지 않는 버튼 비활성화 */
  controls = {
    mapTypeControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  };

  #selectedHotel?: IMrhst;

  /** 호텔  선택처리 */
  @Input()
  set selectedHotel(hotel: IMrhst) {
    this.#selectedHotel = hotel;
    // if (this.infoWindow) {
    //   this.infoWindow.close();
    //   const a: MapAnchorPoint = null;
    //   this.infoWindow.open(a);
    // }
  }

  /** 현재 선택된 호텔 */
  get selectedHotel(): IMrhst {
    return this.#selectedHotel!;
  }

  /** 마커 위치 변경 시 위도 경도값 이벤트 */
  @Output()
  latLng: EventEmitter<{ lat: number; lng: number }> = new EventEmitter();

  /** 지도 마커 위치 */
  @Input() markers: google.maps.LatLngLiteral[] = [];

  /** 지도 마커 위치 */
  @Input() hotelList?: IMrhst[];

  /** 마커 옵션. 기본 드래그 방지 */
  markerOptions: google.maps.MarkerOptions = { draggable: false };

  @ViewChild(MapInfoWindow) infoWindow?: MapInfoWindow;

  /** 확대 레벨 */
  private _zoom = 12;

  @Input() set zoom(value: number) {
    this._zoom = value;
  }

  /** 확대 레벨 */
  get zoom(): number {
    return this._zoom;
  }

  /** google maps api 가 로드되었는지 상태 확인 */
  apiLoaded: Observable<boolean>;

  constructor(private googleMapService: GoogleMapService) {
    this.apiLoaded = this.googleMapService.apiLoaded;
  }

  onMapClick(idx?: number, marker?: MapMarker): void {
    if (this.hotelList && this.hotelList.length > 0) {
      this.#selectedHotel = this.hotelList[idx!];
      this.infoWindow?.open(marker);
    }
  }

  ngOnInit(): void {}

  onFavIconClick(item?: IMrhst): void {
    // this.favService.toggleFacilityFavItem(item);
  }

  hasFacilityFavItem(hotelId?: number): void {
    // this.favService.hasFacilityFavItem(hotelId);
  }
}
