<div *ngIf="apiLoaded | async">
  <google-map
    [center]="center"
    [zoom]="zoom"
    width="100%"
    height="100%"
    [options]="controls"
    (mapClick)="onMapClick()"
  >
    <map-marker
      *ngFor="let markerPosition of markers; index as i"
      [position]="markerPosition"
      [options]="markerOptions"
      (mapClick)="onMapClick(i, marker)"
      #marker="mapMarker"
    ></map-marker>
    <map-info-window *ngIf="infoDisplayMode === 'rsrv'">
      <div class="map-result-item" *ngIf="selectedHotel">
        <img
          class="fav-icon"
          src="/assets/imgs/fav-icon.png"
          [ngClass]="{
            on: hasFacilityFavItem(selectedHotel.id)
          }"
          (click)="onFavIconClick(selectedHotel)"
        />
        <div class="map-img-wrapper">
          <img class="map-img" [src]="selectedHotel.imgUrlList?.[0]" />
        </div>
        <div class="result-item-info">
          <div>
            <span class="item-content">{{
              selectedHotel | locale: 'mrhstNm'
            }}</span>
            <span class="item-nm">{{ selectedHotel.address }}</span>
          </div>
          <div class="item-amt-wrapper">
            <button class="item-amt">
              {{ 0 | currency: 'JPY' }} / 1{{ 'night' | translate }}
            </button>
          </div>
        </div>
      </div>
    </map-info-window>
    <map-info-window *ngIf="infoDisplayMode !== 'rsrv'">
      <div class="map-result-item" *ngIf="selectedHotel">
        <div class="result-item-info">
          <div>
            <span class="item-nm">{{ selectedHotel | locale: 'mrhstNm' }}</span>
            <span class="item-content">{{ selectedHotel.address }}</span>
          </div>
        </div>
      </div>
    </map-info-window>
  </google-map>
</div>
